import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import UlrichHemel from "../images/Foto-Ulrich-Hemel-Wirtschaftswissenschaft-Sozialstrategie.jpg"
import MarkusWarode from "../images/Foto-Markus-Warode-Sozialwissenschaft-Arbeitswissenschaft.jpg"
import OliverStrauß from "../images/OliverStrauss.jpg"
import HaraldLink from "../images/HaraldLink.jpg"

const Partner = () => (
  <Layout>
    <SEO title="Partner" />
    <section id="about-hero" className="about-hero">
      <h1 className="about-headline">Über Uns</h1>
    </section>
    <section className="about">
      <div className="about-container">
        <img className="partner-img" src={UlrichHemel} alt="Ulrich Hemel" />
        <p>
          <b>Prof. Dr. Dr. Ulrich Hemel</b> ist der Unternehmensgründer und hat
          stets das Zusammenspiel von wirtschaftlicher Praxis und
          wissenschaftlicher Erkenntnis gefördert.
        </p>
        <p>
          Seine parallelen Abschlüsse in Sozial- und Wirtschaftswissenschaften
          (Lizentiat) und katholischer Theologie (Promotion und Habilitation)
          verschaffen ihm einen weiten Horizont, vertieft durch seine frühere
          Tätigkeit bei The Boston Consulting Group und als Vorstand und
          Vorstandsvorsitzender der PAUL HARTMANN AG.
        </p>
        <p>
          Neben Erfahrungen im Umfeld von Private Equity ist er u.a. Gründer des
          Instituts für Sozialstrategie zur Erforschung der globalen
          Zivilgesellschaft. 2003 wurde er Manager des Jahres (BDU), 2005
          erhielt sein Buch „Wert und Werte“ die Auszeichnung als
          Wirtschaftsbuch des Jahres (Financial Times Deutschland).
        </p>
      </div>
    </section>
    <section className="about-hero"></section>
    <section className="about">
      <div className="about-container">
        <img className="partner-img" src={MarkusWarode} alt="Markus Warode" />
        <p>
          <b>Markus Warode</b> verbindet eine reflektierte, ethischen
          Wertehaltung mit modernem Führungs- und Managementhandeln. Durch seine
          Abschlüsse in Sozial- und Arbeitswissenschaft fokussiert er auf einen
          systemischen Ansatz, der den Menschen mit seinen Einstellungen und
          Fähigkeiten als Pfeiler unternehmerischer Entwicklung in den
          Mittelpunkt stellt.
        </p>{" "}
        <p>
          Er verfügt über jahrelange Erfahrungen in Hochschulen,
          Wirtschaftsverbänden, Sozialwirtschaft und weiteren Unternehmen und
          Organisationen. Seine Stärke ist das Zusammenspiel wissenschaftlicher
          Expertise und organisationaler Praxis.
        </p>
      </div>
    </section>
    <section className="about-hero"></section>
    <section className="about">
      <div className="about-container">
        <img className="partner-img" src={OliverStrauß} alt="Oliver Strauß" />
        <p>
          <b>Oliver Strauß</b> hat als Interim Manager und Berater (davon über 7
          Jahre bei der Boston Consulting Group) mehr als 50 große Unternehmen
          und Mittelständler dabei unterstützt, ihre Marktposition und
          Ertragssituation nachhaltig zu verbessern – auch im Auftrag namhafter
          Private-Equity-Investoren. Seit 2004 gibt er parallel als Business
          Angel der NRW-Bank sein Wissen an Startups weiter und hilft ihnen,
          erfolgreich Märkte zu erobern.
        </p>{" "}
        <p>
          Er ist Bankkaufmann, hat Betriebswirtschaft in Deutschland, Frankreich
          und den USA studiert, eine Beratungsgesellschaft mit über 30
          Mitarbeitern sowie eine Market- und Customer-Insight-Online-Plattform
          aufgebaut. Der EU-zertifizierte Mediator und Compliance-Experte hat
          sich aktuell an einem Fachbuch mit einem Beitrag zu ‘Trust-based
          Marketing‘ beteiligt.
        </p>
      </div>
    </section>
    <section className="about-hero"></section>
    <section className="about">
      <div className="about-container">
        <img className="partner-img" src={HaraldLink} alt="Harald Link" />
        <p>
          <b>Harald Link</b> ist seit mehr als 35 Jahren selbständiger
          Beteiligungs- und Fusionsberater und hat in dieser Zeit weit über 100
          nationale und internationale Transaktionen – vor allem im „gehobenen“
          Mittelstand bei Mandanten, die ihm seit vielen Jahren vertrauen –
          aktiv, diskret, professionell und erfolgreich begleitet.
        </p>
        <p>
          Nach einem Studium mit Abschluss als Dipl.-Kaufmann an der Universität
          Hamburg war er mehrere Jahre in der Wirtschaftsprüfung/Steuerberatung
          und als Investment-Banker in New York (Börsenexamen an der Wall
          Street) tätig.
        </p>
        <p>
          1984 wurde Harald Link in den Bundesverband Deutscher
          Unternehmensberater (BDU) aufgenommen und war langjähriger
          persönlicher Berater von S.D. Fürst Ferdinand von Bismarck,
          Friedrichsruh/Marbella.
        </p>
      </div>
    </section>
    <section className="about-hero"></section>
  </Layout>
)

export default Partner
